<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>在线商家</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入用户名进行搜索"
            v-model="queryinfo.query"
            clearable
            size="small"
            @clear="getOnlineList()"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @mouseover.native="mouseOver()"
              @mouseout.native="mouseLeave()"
              @click="getOnlineList()"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <br />
        <!-- table 表格区域
        <el-button type="primary" size="mini" icon="fa fa-check" plain
          >&nbsp;批量置为无效</el-button
        >-->
      </el-row>
      <!-- table 表格区域 -->
      <el-table
        :data="goodsList"
        border
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="#"></el-table-column>
        <!-- table 扩展区域 -->
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="登陆时间">
                <span>{{ props.row.xipAddress.createTime }}</span>
              </el-form-item>
              <br />
              <el-form-item label="登录方式">
                <span>{{ props.row.xipAddress.type }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          label="用户名"
          prop="username"
          width="130px"
        ></el-table-column>
        <el-table-column label="头像" prop="avatar" width="66px">
          <template slot-scope="scope">
            <el-avatar :size="'samll'" :src="scope.row.avatar"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column label="上线IP" prop="placeIn" width="240px">
          <template slot-scope="scope">
            <el-tag size="medium">{{ scope.row.xipAddress.ipAddress }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="登录os" prop="placeOut" width="240px">
          <template slot-scope="scope">
            <el-tag size="medium" type="warning">{{
              scope.row.xipAddress.os
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="登录浏览器" prop="placeOut" width="240px">
          <template slot-scope="scope">
            <el-tag size="medium" type="warning">{{
              scope.row.xipAddress.browser
            }}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column width="80px" label="评分" prop="star">
          <template slot-scope="scope">
            <el-badge
              style="margin-top:1em;"
              :value="scope.row.star"
              class="item"
              type="primary"
            >
            </el-badge>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="内容" prop="starContent">
          <template slot-scope="scope">
            {{ scope.row.starContent }}
          </template>
        </el-table-column> -->
        <!-- <el-table-column fixed="right" width="80px" label="操作"> -->
        <!-- <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              @change="usersChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column> -->
        <el-table-column
          style="padding:0;"
          fixed="right"
          label="操作"
          min-width="180px"
        >
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              icon="fa fa-power-off"
              plain
              @click="offlineUser(scope.row)"
              >&nbsp;下线用户</el-button
            >
            <el-button
              type="success"
              size="mini"
              icon="fa fa-paper-plane"
              plain
              @click="sendMsg(scope.row)"
              >&nbsp;推送消息</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 页面分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import Qs from 'qs'
export default {
  name: 'orderstar',
  data() {
    return {
      // 查询参数对象
      queryinfo: {
        query: '',
        pagenum: 1,
        pagesize: 5
      },
      goodsList: [],
      total: 0,
      visible: false,
      lists: []
    }
  },
  methods: {
    async offlineUser(row) {
      const { data: res } = await this.$http.post(
        'user/sendMsg',
        Qs.stringify({
          to: row.username,
          type: 'offline'
        })
      )
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.$antMessage.success('下线成功')
    },
    async sendMsg(row) {
      var that = this
      this.$prompt('实时推送消息', '请输入消息内容', {
        confirmButtonText: '推送',
        cancelButtonText: '取消'
      })
        .then(async ({ value }) => {
          const { data: res } = await this.$http.post(
            'user/sendMsg',
            Qs.stringify({
              to: row.username,
              type: 'msg',
              message: value
            })
          )
          if (res.code !== '20000') {
            this.$message({
              type: 'error',
              message: res.message
            })
            return false
          } else {
            this.$message({
              type: 'success',
              message: res.message
            })
            return false
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '你取消了操作'
          })
        })
    },
    mouseOver() {
      this.active = 'background-color: #ccc;'
      //   console.log('鼠标移动事件')
    },
    mouseLeave() {
      this.active = ''
    },
    // 监听开关状态的改变
    async usersChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `stars/${userInfo.id}/state/${userInfo.status}`
      )
      if (res.code !== '20000') {
        userInfo.status = !userInfo.status
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    },
    // 根据分页获取商品列表的全部数据
    async getOnlineList() {
      const { data: res } = await this.$http.get('online', {
        params: this.queryinfo
      })
      if (res.code !== '20000') {
        this.$message.error(res.message)
        return
      }
      this.$message.success('获取在线商家列表成功！')
      console.log(res.data)
      this.goodsList = res.data.lists
      this.total = Number(res.data.total)
    },
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize
      this.getOnlineList()
    },
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage
      this.getOnlineList()
    },
    handleSelectionChange(val) {
      // var listTemp = []
      // val.forEach(item => {
      //   listTemp.push(Number(item.goodsId))
      // })
      // this.goodsArr = listTemp
    },
    addCoupon() {}
  },
  mounted() {},
  created() {
    this.getOnlineList()
  }
}
</script>

<style lang="less" scoped></style>
